<template>
  <portal to="dialog">
    <ws-dialog
        v-model="display"
        overlay-color="transparent"
        fullscreen-align="right"
        fullscreen-width="400"
        width="320"
        :title="$t('avalon.element.edit')"
        not-centered
        no-padding
        margin-right="25px"
        margin-top="68px"
        margin-bottom="25px"
        border-radius="8"
        no-navigation
        blank
        :deselect-blind-zone-x="WINDOW_WIDTH - 345"
        height="90%"
        max-height="640px"
        :avalon-sidebar="!$store.state.avalon.imageLibraryOn"
    >

      <template #header>
        <ws-navigation-header
            style="width: 100%"
            v-model="navigation"
            :items="navigationSelect"
            no-number
            padding="20px"
            class="my-2"
        />
        <portal-target name="block_settings_return"></portal-target>
      </template>

      <template #default>
        <div class="pa-6" ref="dialogContent">
          <!-- Content -->
          <div v-if="navigation === 'content'" class="fill-height">
            <component
                v-if="settingsComponent"
                :is="settingsComponent"
                v-model="element.config"
                :element="element"
                :block-style="blockStyle"
                @input="editElement()"
                @update-element="editElement"
                @sync-grid="$emit('sync-grid')"
            />
          </div>

          <div v-if="navigation === 'style'" class="fill-height">
            <component
                v-if="settingsComponent"
                :is="settingsComponent"
                v-model="element.config"
                :element="element"
                @input="editElement()"
                @update-element="editElement"
                :block-style="blockStyle"
                is-style
            />
          </div>

          <div v-if="navigation === 'format'" class="fill-height">
            <component
                v-if="settingsComponent"
                :is="settingsComponent"
                v-model="element.config"
                :element="element"
                @input="editElement()"
                @update-element="editElement"
                :block-style="blockStyle"
                is-format
            />
          </div>


        </div>
      </template>

      <template #footer>
        <v-sheet class="px-6 pt-6 pb-3">
          <div>
            <portal-target name="element_settings_footer"></portal-target>
          </div>
          <ws-button
              @click="resetChanges"
              :disabled="!resetActive"
              label="ResetSettings"
              height="40"
              block
              outlined
              class="mb-3"
          />
        </v-sheet>
      </template>

    </ws-dialog>
  </portal>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "avalonBlocSettings",
  components : {
    avalonTextSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonTextSettings"),
    avalonImageSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonImageSettings"),
    avalonButtonSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonButtonSettings"),
    avalonRectangleSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonRectangleSettings"),
    avalonLineSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonLineSettings"),
    avalonFormSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonFormSettings"),
    avalonAccordionSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonAccordionSettings"),
    avalonVideoSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonVideoSettings"),
    avalonModalSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonModalSettings"),
    avalonListSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonListSettings"),
    avalonCourseContentSettings : () => import("@/components/AvalonEditor/Dialogs/elements/avalonCourseContentSettings")
  },
  props : {
    value : {
      type : Boolean,
      default : false,
    },
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      navigation : 'content',
      display : false,
      preventClose : false,
      resetActive : false,
      bufferElement : null
    }
  },
  computed : {
    ...mapState('avalon',['course' , 'pagesSelect' , 'coursesSelect']),
    ...mapState('avalonPage',['course']),

    settingsComponent() {

      switch(this.element.type) {
        case 'text'      : return 'avalonTextSettings'
        case 'image'     : return 'avalonImageSettings'
        case 'button'    : return 'avalonButtonSettings'
        case 'rectangle' : return 'avalonRectangleSettings'
        case 'line'      : return 'avalonLineSettings'
        case 'list'      : return 'avalonListSettings'
        case 'course-content' : return 'avalonCourseContentSettings'
        case 'form'      : return 'avalonFormSettings'
        case 'accordion' : return 'avalonAccordionSettings'
        case 'video'     : return 'avalonVideoSettings'
        case 'modal'     : return 'avalonModalSettings'
        default : return null
      }

    },

    navigationSelect() {
      let items =  [
        { text : this.$t('Content') , value : 'content' },
        { text : this.$t('Style')   , value : 'style'   }
      ]

      if ( ['text' , 'rectangle' , 'line'  ].includes(this.element.type ) ) {
        items.splice(0,1)
      }
      if ( ['video'  ].includes(this.element.type ) ) {
        items.splice(1,1)
      }

      if ( ['text' , 'accordion' , 'list' , 'course-content' ].includes(this.element.type ) ) {
        items.splice(1,0 ,  { text : this.$t('Format')   , value : 'format'   })
      }

      return items
    },


    buttonActions() {
      return [
        { text : this.$t('OpenPage') , value : 'page' },
        { text : this.$t('OpenLink') , value : 'link' },
        { text : this.$t('EnrollOnCourse') , value : 'enroll_on_course' },
        // { text : this.$t('OpenLogin') , value :  'auth' },
        { text : this.$t('GotoDashboard') , value :  'dashboard' },
      ]
    },
    blockSections() {
      let items = [
        { name : this.$t('Style') , value : 'background' , icon : 'mdi-palette', expanded : true },
     //   { name : this.$t('Paddings') , value : 'format', icon : 'mdi-arrow-all' , expanded : false  },
      ]
      if (this.element.type === 'text' ) {
        items.push(   { name : this.$t('Paddings') , value : 'format', icon : 'mdi-arrow-all' , expanded : false  } )
      }

      if (this.element.type === 'button' ) {
        items.push(  { name : this.$t('Button') , value : 'button', icon : 'mdi-button-cursor' , expanded : true  } )
      }

      if (this.element.type === 'line' ) {
        items.push(  { name : this.$t('Line') , value : 'line', icon : 'mdi-button-cursor' , expanded : true  } )
      }

      if (this.element.type === 'form' ) {
        items.push(  { name : this.$t('Form') , value : 'form', icon : 'mdi-form-select' , expanded : true  } )
      }


      if (this.element.type === 'image' ) {
        return [  { name : this.$t('Image') , value : 'image', icon : 'mdi-image' , expanded : true  } ]
      }

      return items

    },
    backgroundTypes() {
      return [
        { text : this.$t('Color') , value : 'color' , icon : 'mdi-palette'},
        // { text : this.$t('Image') , value : 'image' , icon : 'mdi-image'},
        // { text : this.$t('Video') , value : 'video' , icon : 'mdi-video'},
        { text : this.$t('None')  ,  value : null   , icon : 'mdi-cancel'}
      ]
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input',this.display)
      }
    }
  },
  methods : {
    ...mapActions('avalonPage',['EDIT_ELEMENT']),

    resetChanges() {
      this.$emit('update' , this.COPY(this.bufferElement) )
      this.editElement()
      this.resetActive = false
    },
    editImage($event) {
      this.element.text = $event.url
      this.editElement()
    },
    async editElement(element = null) {
      this.resetActive = true
      element = element || this.element

      this.EDIT_ELEMENT(element)
    },

    formLeadChange(value , field) {
      if ( !value ) {
        this.element.config.fields = this.element.config.fields.filter( el => el !== field)
      } else {
        if (  !this.element.config.fields.includes(field) ) {
          this.element.config.fields.push(field)
        }
      }

      this.element = Object.assign({} , this.element)
      this.editElement()
    },

    // line
    editLineValign(value) {
      this.element.config.valign = value
      this.element = Object.assign({} , this.element )
      this.editElement()
    }
  },
  beforeMount() {
    this.bufferElement = this.COPY(this.element)
  },
  beforeDestroy() {
    this.$store.state.avalon.elementSettingsOn = false
    this.$store.state.avalon.selectedElementDesign = null
    this.$store.state.avalon.selectedElementType = null
    this.$store.state.avalon.selectedElementStyle = null
    this.$store.state.avalon.selectedBlockStyle = null
    this.$store.state.avalon.selectedBlockType = null
    this.$store.state.avalon.selectedBlockDesign = null
  },
  mounted() {
    this.$store.state.avalon.elementSettingsOn = true
    setTimeout(()=> {
      this.$store.state.avalon.selectedElement = this.element.id
    },1)
    if ( !this.$store.state.avalon.menuNavigationStyleTheme ) {
      this.$store.state.avalon.menuNavigationStyleTheme = this.blockStyle
    }
    this.$store.state.avalon.selectedElementType = this.element.type
    this.$store.state.avalon.selectedBlockStyle = this.blockStyle || null
    setTimeout(()=> {
      this.$refs.dialogContent.scrollTop = 0
    },1)

    if ( ['text' , 'rectangle' , 'line' ].includes(this.element.type ) ) {
      this.navigation = 'style'
    }

    this.display = this.value
    if (!this.element.config) {
      this.element.config = {}
    }
    if (!this.element.config.course && this.course.uuid ) {
      this.element.config.course = this.course.uuid
    }
  }
}
</script>

<style scoped>
.block-style {
  position:fixed;
  outline: none;
  top:0;bottom: 0;
  right: 0;
  height: 100% ;
  z-index:1111
}
</style>